import React from 'react'

import styled from 'styled-components'
import ScrollableAnchor from 'react-scrollable-anchor'

function Atos() {
  return (
    <ScrollableAnchor id={'atosConstitutivos'}>
      <AtosContent>
        <h1>Atos Constitutivos e Registros</h1>
        <hr />
          <p>A MICHELON AUDITORES é uma sociedade simples de prestação de serviços, cujo contrato social se encontra registrado no Cartório de Registro Civil das Pessoas Jurídicas sob o N° 71.738, no Livro “A”, Nº  131. Igualmente a Empresa está registrada no Conselho Regional de Contabilidade sob número CRC-RS 4.626, Comissão de Valores Mobiliários e CGC(MF) nº 09.356.626/0001-00, e apta para efetuar trabalhos de auditoria em empresas regulamentadas pelo Banco Central do Brasil.</p>
      </AtosContent>
    </ScrollableAnchor>
  )
}

export default Atos;

const AtosContent = styled.section`
    background-color: white;
    padding: 0 250px 20px 250px;
    text-align: justify;
    align-self: center;
    padding-bottom: 50px;

    @media only screen 
    and (min-width: 320px) 
    and (max-width: 568px) {

      padding: 0 40px 50px 40px;
    }
`