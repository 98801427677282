import React from 'react'

import styled from 'styled-components'
import ScrollableAnchor from 'react-scrollable-anchor'

function Diferencial() {
  return (
    <ScrollableAnchor id={'diretrizes'}>
      <DiferencialContent>
        <h1>Diferencial</h1>
        <hr />
        <p>O enfoque de nosso trabalho transcende a emissão de pareceres ou relatórios, atuando como parceiros de nossos clientes, através de um processo interativo, moderno auxiliando nossos clientes na busca de soluções adequadas às necessidades particulares de cada empresa/entidade.</p>
      </DiferencialContent>
    </ScrollableAnchor>
  )
}

export default Diferencial;

const DiferencialContent = styled.section`
  text-align: justify;
  align-self: center;
  margin: 0 250px 70px 250px;
  
    h1 {
      padding-top: 50px;
      margin-top: 0;
    }

    @media only screen 
    and (min-width: 320px) 
    and (max-width: 568px) {

      margin: 0 40px 60px 40px;
  }
`