import React from 'react'

import styled from 'styled-components'
import PersonIcon from '@material-ui/icons/Person'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import CreateIcon from '@material-ui/icons/Create';
import fotoContato from '../../assets/fotoContato.png'
import ScrollableAnchor from 'react-scrollable-anchor'

function Contato() {
    return (
        <ScrollableAnchor id={'contato'}>
            <ContatoContent>
                <h1>Contato</h1>
                <hr/>
                <div>
                    <div>
                        <div className='informacoes'>
                            <h3>Fale Conosco</h3>
                            <p>(51) 3072-2368 - michelon@michelonauditores.com.br</p>
                            <p>Ou envie sua mensagem que retornaremos o mais breve possível. Todos os campos são obrigatórios.</p>
                        </div>
                        <form name='contact' method='post'>
                            <input type='hidden' name='form-name' value='contact' />
                            <div>
                                <PersonIcon alt='iconePerson' htmlColor='#906f3c' fontSize='large'/>
                                <input type='text' name='name' placeholder='Nome' data-required="true" />
                            </div>
                            <div>
                                <MailOutlineIcon alt='iconePerson' htmlColor='#906f3c' fontSize='large'/>
                                <input type='email' name='email' placeholder='E-mail' data-required="true"/>
                            </div>
                            <div>
                                <PhoneIcon alt='iconePerson' htmlColor='#906f3c' fontSize='large' />
                                <input type='fone' name='fone' placeholder='Telefone' data-required="true" className="input-field" />
                            </div>
                            <div>
                                <CreateIcon alt='iconePerson' htmlColor='#906f3c' fontSize='large'/>
                                <textarea rows='10' name='message' placeholder='Mensagem...' data-required="true" />
                            </div>
                            <button type='submit'>ENVIAR</button>
                        </form>
                    </div>
                    <div className='displaycontato'>
                        <div className='informacoes'>
                            <h3>Endereço</h3>
                            <p>Rua Santos Dumont, 1500, Sala 1203 - CEP: 90230-240. Bairro: Floresta - Porto Alegre/RS</p>
                        </div>
                        <iframe title='mapa' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6909.953986076083!2d-51.206139!3d-30.008817!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95197996ad2a6319%3A0xfa4e638d3a6c6765!2sR.%20Santos%20Dumont%2C%201500%20-%20Floresta%2C%20Porto%20Alegre%20-%20RS%2C%2090230-241!5e0!3m2!1spt-BR!2sbr!4v1594166020489!5m2!1spt-BR!2sbr" height="375" frameBorder="0" aria-hidden="false" tabIndex="0"></iframe>
                    </div>
                </div>
            </ContatoContent>
        </ScrollableAnchor>
    )
}

export default Contato;

const ContatoContent = styled.section`
    background-image: linear-gradient(0deg, rgba(15, 16, 43), rgba(15, 16, 43, 0.82)), url(${fotoContato});
    background-size: cover;
    background-attachment: fixed;
    background-position: right;
    padding: 0 160px 40px 160px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

    .MuiSvgIcon-fontSizeLarge {
        font-size: 1.8rem;
        padding: 3px 0 0 3px;
    }

    & > div {
        display: flex;
        & > div {
            display: flex;
            flex-direction: column;
            width: 50%;
            &:first-child {
                margin-right: 50px;
            }
        }
    }

    h1 {
        padding-top: 40px;
        color: white;
    }

    h3 {
        color: white;
    }

    hr {
        background-image: linear-gradient(to right, transparent, goldenrod, transparent);
    }

    p {
        color: white;
    }

    .informacoes {
        flex-direction: column;
        padding-bottom: 20px;
    }

    .informacoes:last-child {
        flex-direction: column;
        margin-left: -16px;
        padding-bottom: 20px;
    }

    .displaycontato {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 40px;
    }

        div {
            display: flex;
        }

    iframe {
        margin-bottom: 50px;
        width: 100%;
        height: 64vh;
        max-height: 400px;
    }

    form {
        display: flex;
        flex-direction: column;
        & > div {
            margin-bottom: 10px; 
            width: 88%;

            svg { 
                position: absolute; 
            }

            input, textarea {
                padding-left: 50px;
            }
        }
    }

    input, textarea {
        margin-bottom: 15px;
        padding: 10px;
        border-style: none;
        background-color: white;
        border-radius: 4px;
        width: 100%;
    }

    button {
        padding: 10px;
        color: white;
        border-radius: 4px;
        background-color: #906f3c;
        border-color: #906f3c;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
        cursor: pointer;
        width: 88%;
        align-self: flex-start;
    }


    @media only screen 
    and (min-width: 320px) 
    and (max-width: 568px) {

        padding: 0 40px 50px 40px;

        h3, p {
            padding-left: 0;
        }

        .informacoes {
            width: 100%;
            padding: 0 0 50px 0;
            text-align: center;
        }

        div {
            flex-direction: column;
            text-align: center;
        }

        div > div {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
        }

        div > div:first-child {
            margin-right: 0;
        }

        .displayContato {
            flex-direction: column;
        }

        .informacoes:last-child {
            margin-left: 0;
        }

        form {
            width: 95%;
            padding-bottom: 50px;
            padding-right: 0;
        }


        input, textarea {
            width: 90%;
        }

        button {
            width: 100%;
            align-self: center;
        }

        iframe {
            width: 100%;
            margin-bottom: 0;
            height: 40vh;
        }
    }
`