import React from 'react'

import styled from 'styled-components'

import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import CreateIcon from '@material-ui/icons/Create';
import ScrollableAnchor from 'react-scrollable-anchor'

function Atuacao() {
    return (
        <ScrollableAnchor id={'servicos'}>
            <AtuacaoContent>
                <h1>Serviços</h1>
                <hr />
                <div className='servicos'>
                    <div>
                        <div className='titulos'>
                            <WorkOutlineIcon alt='iconeAuditoria' htmlColor='#906f3c' fontSize='small' />
                            <h2>Auditoria</h2>
                        </div>
                        <ul>
                            <li>Auditoria Contábil</li>
                            <li>Auditoria Fiscal e Tributária</li>
                            <li>Auditoria Operacional e de Gestão</li>
                            <li>Auditoria Interna (terceirização)</li>
                            <li>Auditoria de Sistemas</li>
                            <li>Auditoria Trabalhista e Previdenciária</li>
                            <li>Auditoria de Controles Internos</li>
                        </ul>
                    </div>
                    <div>
                        <div className='titulos'>
                            <CreateIcon alt='iconeConsultoria' htmlColor='#906f3c' fontSize='medium' />
                            <h2>Consultoria</h2>
                        </div>
                        <ul>
                            <li>Planejamento Estratégico</li>
                            <li>Custo ABC</li>
                            <li>Implantação de Sistema de Custos</li>
                            <li>Implantação de Auditoria Interna</li>
                            <li>Implantação de Programas da Melhoria da Qualidade</li>
                            <li>Análise e Melhoria da Estrutura Organizacional e dos Processos</li>

                            <li>Controle do Imobilizado</li>
                            <li>Análise e Estruturação da Área de Informática</li>
                            <li>Consultoria em Recursos Humanos</li>
                            <li>Avaliação Patrimonial</li>
                            <li>Avaliação de Empresas</li>
                            <li>Mapeamento dos Riscos (negócios, econômicos/financeiros, processos, tecnológico, pessoal, políticos/políticas públicas, legais e meio ambiente)</li>
                        </ul>
                    </div>
                </div>
            </AtuacaoContent>
        </ScrollableAnchor>
    )
}

export default Atuacao;

const AtuacaoContent = styled.section`
    background-color: white;
    margin: 0 30px;
    color: black;

    h1 {
        padding-top: 40px;
        margin-top: 0;
    }

    hr {
        background-image: linear-gradient(to right, transparent, goldenrod, transparent);
    }
    
    & > .servicos {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        & > div {
            display: flex;
            align-items: center;
        }

        & > div:last-child {
            width: 40%;
            padding-left: 80px;
        }
    }

    .displayUl {
        display: flex;
    }

    .titulos {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

        div:first-child {
            text-align: left;
            justify-content: center;
        }

        div:last-child {
            text-align: left;
        }

        div > svg {
            color: #906f3c;
            font-size: 4.2rem;
        }

        ul {
            list-style-type: square;
        }

        
    @media only screen 
    and (min-width: 320px) 
    and (max-width: 568px) {

        & > .servicos {
            display: flex;
            flex-direction: column;
            margin-left: 0;
            margin-right: 0;
            justify-content: center;
            padding-bottom: 50px;
        }

        div {
            width: 100%;
            padding-right: 0;
            display: flex;
            flex-direction: column;
        }

        .servicos > div:last-child {
            width: 100%;
            padding-left: 0;
            padding-top: 50px;
        }

            p {
                padding: 0 25px 30px 25px;
            }

        .titulos {
            margin-right: 0;
        }
   
    }
`
