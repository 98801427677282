import React from 'react';

import Main from './pages/main/Main'
import { configureAnchors } from 'react-scrollable-anchor'

configureAnchors({offset: -120, scrollDuration: 400})

function App() {
  return (
    <>
      <Main/>
    </>
  )
}

export default App;