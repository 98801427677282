import React from 'react'

import fotoEquipe from '../../assets/fotoEquipe.png'
import logoHorizontal from '../../assets/logoHorizontal.svg'
import ScrollableAnchor from 'react-scrollable-anchor'

import styled from 'styled-components'

function Capa() {
    return (
        <ScrollableAnchor id={'capa'}>
            <CapaContent>
                <img src={logoHorizontal} alt='logo'></img>
            </CapaContent>
        </ScrollableAnchor>
    )
}

export default Capa;

const CapaContent = styled.section`
    background-image: linear-gradient(0deg, white, rgba(12, 11, 12, 0.2)), url(${fotoEquipe});
    background-size: cover;
    height: 600px;
    align-items: center;
    background-attachment: fixed;
    background-position: center;

    h1 {
        color: white;
        font-size: 50px;
        padding-top: 190px;
        text-align: center;
    }

    img {
        position: absolute;
        top: 350px;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
    }

    @media only screen 
    and (min-width: 320px) 
    and (max-width: 568px) {
       
        background-attachment: scroll;
        height: 454px;

        img {
            display: none;
        }
    } 

`
