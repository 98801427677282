import React from 'react'

import styled from 'styled-components'
import ScrollableAnchor from 'react-scrollable-anchor'

function Profissionais() {
    return (
        <ScrollableAnchor id={'equipe'}>
            <ProfissionaisContent>
                <div className='equipe'>
                    <h1>Equipe</h1>
                    <hr />
                    <div>
                        <p>Nossa equipe é formada por consultores e auditores, com larga experiência em empresas de auditoria e consultoria de grande porte.</p>
                        <p>Em nossa empresa o treinamento é prioridade absoluta, visando manter nossos profissionais permanentemente atualizados com as mais modernas técnicas de auditoria e consultoria, dentro de suas áreas de atuação.</p>
                        <p>Todos os trabalhos são acompanhados diretamente e, em muitos casos, até desenvolvidos pessoalmente pelos nossos sócios.</p>
                        <p>Dentro de nossa equipe destacamos, profissionais com larga experiência em matérias de natureza Tributária, Custos, Reorganização de Processos Administrativos, Redução de Perdas e Aumento da Produtividade e Implantação de processos, CCQ e Melhoria da Qualidade.</p>
                    </div>
                </div>
            </ProfissionaisContent>
        </ScrollableAnchor>
    )
}

export default Profissionais;

const ProfissionaisContent = styled.section`
    display: flex;

    & > div {
        margin: 0 250px 50px 250px;
        text-align: justify;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

        p {
            padding-left: 0;
        }

        h1 {
            padding-top: 0;
        }

    }

    @media only screen 
    and (min-width: 320px) 
    and (max-width: 568px) {

        & > div {
        margin: 0 40px 50px 40px;
        }

        p {
            padding-left: 0;
        }
    }

`