import React from 'react'

import styled from 'styled-components'
import ScrollableAnchor from 'react-scrollable-anchor'

function Apresentacao() {
    return (
        <ScrollableAnchor id={'escritorio'}>
            <ApresentacaoContent>
                <h1>A Firma</h1>
                <hr />
                <div>
                    <p>A MICHELON AUDITORES é uma empresa prestadora de serviços de Auditoria e Consultoria Contábil, formada por contadores com destacada experiência no ramo, desenvolvendo atividades de revisão dos procedimentos e registros das demonstrações contábeis e financeiras de empresas, associações, e entidades públicas.</p>
                    <p>Nosso objetivo é prestar serviços de auditoria e assessoria na área contábil, visando a confiabilidade e transparência das demonstrações financeiras.</p>
                    <p>Nossas soluções se adaptam às características de cada empresa, considerando o grau de organização interna, evolução tecnológica, recursos humanos e financeiros, priorizando soluções urgentes e adequadas.</p>
                </div>
            </ApresentacaoContent>
        </ScrollableAnchor>
    )
}

export default Apresentacao;

const ApresentacaoContent = styled.section`
    background-color: #8f6f3c26;

    & > div {
        margin: 0 250px 20px 250px;
        text-align: justify;
        align-self: center;
        padding-bottom: 50px;
    }

        p {
            align-items: center;
        }

    @media only screen 
    and (min-width: 320px) 
    and (max-width: 568px) {
        
        & > div {
            margin: 0 40px 0 40px;
            text-align: justify;
            display: flex;
            flex-direction: column;
            align-self: center;
        }
    }

`