import React from 'react'

import styled from 'styled-components'

function Footer() {
  return (
    <FooterContent id="footer">
      <h4>SITE E DESIGN DESENVOLVIDOS POR <a href='https://www.renanalvarenga.com.br'> RENAN ALVARENGA</a></h4>
    </FooterContent>
  )
}

export default Footer;

const FooterContent = styled.section`
    background-color: black;
    color: white;
    text-align: center;
    font-size: 11px;
    padding: 5px;
    font-family: sans-serif;

    a {
      color: #906f3c;
      text-decoration: none;
    }

    @media only screen 
    and (min-width: 320px) 
    and (max-width: 568px) {
      padding-left: 0;
      padding-right: 0;
    }
`
