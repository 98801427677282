import React from 'react'

import styled from 'styled-components'

import fotoResponsavel from '../../assets/fotoResponsavel.png'

function Responsavel() {
    return (
        <ResponsavelContent>
            <div className='displayResponsavel'>
                <img src={fotoResponsavel} alt='fotoResponsavel'></img>
                <div>
                    <h3>Responsável Técnico</h3>
                        <p>Vicente Michelon</p>
                        <p>Contador CRC-RS n.º 052365/O-8</p>
                        <p>Formado em Ciências Contábeis e pós-graduado em Administração Estratégica Empresarial pela ULBRA-RS. Exercendo atividade de auditoria independente desde 1988, atuando no planejamento, execução e supervisão de trabalhos de auditoria em empresas de médio e grande porte nos segmentos público e privado. Integrante do CNAI - Cadastro Nacional de Auditores Independentes (sob o número 661), registrado na CVM - Comissão de Valores Mobiliários; membro do IBRACON - Instituto dos Auditores Independentes do Brasil. É especialista em auditoria de instituições financeiras, shoppings centers, empresas da área de saúde e previdência privada, como possui larga experiência no desenvolvimento de trabalhos especiais e revisão das demonstrações contábeis e financeiras.</p>
                </div>
            </div>
        </ResponsavelContent>
    )
}

export default Responsavel;

const ResponsavelContent = styled.section`
    margin: 0 0 -30px 0;
    text-align: justify;
    background-color: #8f6f3c26;

    .displayResponsavel {
        display: flex;
        height: 500px;
    }

    .displayResponsavel img {
        width: 50%;
    }

    p, h3 {
        padding-left: 100px;
        padding-right: 100px;
    }

    p:last-child {
        padding-bottom: 50px;
    }

    h3 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    @media only screen 
    and (min-width: 320px) 
    and (max-width: 568px) {

        .displayResponsavel {
            height: 60%;
        }

        .displayResponsavel img {
            display: none;
        }

        p {
            padding: 0 40px 0 40px;
        }

        h3 {
            padding: 50px 40px 0 40px;
        }
    }
`
