import React from 'react'

import styled from 'styled-components'

import fotoValores from '../../assets/fotoValores.png'

function Valores() {
    return (
        <ValoresContent>
            <div className='displayValores'>
                <div>
                    <h2>Missão</h2>
                    <hr />
                        <p>Prestar os melhores serviços de Auditoria e Consultoria e proporcionar aos nossos clientes e principais interessados os melhores resultados por meio de nosso trabalho.</p>
                </div>
                <div>
                    <h2>Visão</h2>
                    <hr />
                        <p>Sermos reconhecidos pelos nossos clientes como uma das principais empresas no ramo de auditoria e consultoria, auxiliando para seu sucesso e crescimento.</p>
                </div>
                <div>
                    <h2>Valores</h2>
                    <hr />
                    <ul>
                        <li>Foco: na singularidade de cada cliente</li>
                        <li>Resultados: dedicados a solucionar problemas e as necessidades dos nossos clientes</li>
                        <li>Ética, transparência e profissionalismo</li>
                        <li>Empreendedorismo e inovação</li>
                        <li>Pessoas</li>
                        <li>Diversidade humana e cultural</li>
                    </ul>
                </div>
            </div>
        </ValoresContent>
    )
}

export default Valores;

const ValoresContent = styled.section`
    padding: 50px 100px 50px 100px;
    background-image: linear-gradient(0deg, rgba(15, 16, 43), rgba(15, 16, 43, 0.82)), url(${fotoValores});
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    color: white;

    hr {
        background-image: linear-gradient(to right, transparent, goldenrod, transparent);
    }
    
    .displayValores{
        display: flex;
        align-items: baseline;
        justify-content: center;
        width: 100%;
    }

    & > div {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    div {
        width: 30%;
        padding-right: 30px;
    }

    div:last-child {
        padding-right: 0;
    }

        & p {
            margin: 0;
            color: white;
            text-align: center;
        }

    h2 {
        text-align: center;
        color: white;
    }    

    ul {
        list-style-type: square;
    }

    @media only screen 
    and (min-width: 320px) 
    and (max-width: 568px) {
        background-position: initial;
        padding: 50px 50px;

        .displayValores{
            text-align: justify;
            display: flex;
            flex-direction: column;
            align-self: center;
            padding: 0;
        }
        
        div {
            width: 100%;
            padding-right: 0;
            padding-bottom: 50px;
        }

        ul {
            text-align: left;
        }
    }

`