import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import logoHorizontal from '../../assets/logoHorizontal.svg'
import { goToAnchor } from 'react-scrollable-anchor'

function Header() {
  const [scroll, setScroll] = useState(false)
  const [click, setClick] = useState(false)

  const handleScroll = () => {
    const hasScroll = window.pageYOffset > 350
    setScroll(hasScroll)
  }

  useEffect(
    () => {
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    })

  const goToTop = () => {
    goToAnchor('capa')
  }

  const changeEvent = () => {
    setClick(!click)
  }
  return (
    <HeaderContent>
      <nav>
        <img src={logoHorizontal} alt='logo'/>
        <ul>
          <li><a href="#servicos" className="efeitoMenu">Serviços</a></li>
          <li><a href='#escritorio' className="efeitoMenu">A Firma</a></li>
          <li><a href='#diretrizes' className="efeitoMenu">Diretrizes</a></li>
          <li><a href="#equipe" className="efeitoMenu">Equipe</a></li>
          <li><a href="#atosConstitutivos" className="efeitoMenu">Atos Constitutivos</a></li>
          <li><a href="#contato" className="efeitoMenu">Contato</a></li>
        </ul>
      </nav>
      <div className='mobile'>
        <img src={logoHorizontal} alt='logo'/>
        {click && <div onClick={changeEvent} >&#9747;</div>}
        {!click && <div onClick={changeEvent} >&#9776;</div>}
      </div>
      <div className={`menu ${click && 'show'}`}>
        <a href="#servicos" className="efeitoMenu" onClick={changeEvent}>Serviços</a>
        <a href='#escritorio' className="efeitoMenu" onClick={changeEvent}>A Firma</a>
        <a href='#diretrizes' className="efeitoMenu" onClick={changeEvent}>Diretrizes</a>
        <a href="#equipe" className="efeitoMenu" onClick={changeEvent}>Equipe</a>
        <a href="#atosConstitutivos" className="efeitoMenu" onClick={changeEvent}>Atos Constitutivos</a>
        <a href="#contato" className="efeitoMenu" onClick={changeEvent}>Contato</a>
      </div>
      <div onClick={goToTop} className={`scroll ${scroll && 'show'}`}><span>^</span>Top</div>
    </HeaderContent>
  )
}

export default Header;

const HeaderContent = styled.section`
  margin: 0;
  padding: 0;
  background-color: white;
  width: 100%;
  position: fixed;
  font-weight: bold;
  border-bottom: 3px solid #906f3c;
  z-index: 5;

  .scroll {
    visibility: hidden;
    position: fixed;
    right: 10px;
    bottom: 40px;
    background-color: black;
    color: white;
    padding: 10px 20px;
    opacity: .5;
    z-index: 15;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    &.show {
      visibility: initial;
    }
    span {
      font-size: 20px;
      height: 20px;
    }
  }

  .menu, .mobile {
    display: none;
  }

  img {
    transform: scale(1.6);
    position: absolute;
    height: 200px;
    left: 125px;
  }

  .efeitoMenu::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #906f3c;
    transition: width .3s;
  }

  .efeitoMenu:hover::after {
    width: 100%;
    transition: width .3s;
  }

  & > nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 70px;
    height: 120px;
  }

    ul {
      display: flex;
      flex-direction: row;
      margin-left: 215px;
    }

      li {
        padding: 20px;
        cursor: pointer;
        list-style-type: none;
      }

      a {
        text-decoration: none;
        font-size: 14px;
        color: #0f1a2b;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        text-transform: uppercase;
      }

  @media only screen 
  and (min-width: 320px) 
  and (max-width: 568px) {

    img {
      left: 0;
      width: 100%;
    }

    .scroll { 
      display: none;
    }

    nav {
      margin-right: 0;
      display: none;
    }

    .mobile {
      height: 135px;
      display: flex;
      justify-content: space-between;
      margin: 0 40px;
      img {
        width: 54%;
        position: initial;
        height: auto;
        transform: scale(2.4);
      }
      div {
        display: flex;
        align-items: center;
        font-size: 30px;
        color: #906f3c;
      }
    }

    .menu {
      flex-direction: column;
      align-items: center; 
      a {
        font-size: 18px;
        border-bottom: 1px solid lightgray;
        width: 100%;
        text-align: center;
        padding: 15px 0;
        z-index: 10;
      }
      &.show {
        display: flex;
        visibility: visible;
      }
    }
  } 

`