import React from 'react'

import Header from '../header/Header'
import Capa from '../capa/Capa'
import Apresentacao from '../apresentacao/Apresentacao'
import Atuacao from '../atuacao/Atuacao'
import Diferencial from '../diferencial/Diferencial'
import Atos from '../atos/Atos'
import Valores from '../valores/Valores'
import Profissionais from '../profissionais/Profissionais'
import Responsavel from '../responsavel/Responsavel'
import Contato from '../contato/Contato'
import Footer from '../footer/Footer'

function Main() {
  return (
    <>
      <Header />
      <Capa />
      <Atuacao />
      <Apresentacao />
      <Diferencial />
      <Valores />
      <Profissionais />
      <Responsavel />
      <Atos />
      <Contato />
      <Footer />
    </>
  )
}

export default Main;